import React from 'react';
import './Loader.css';
import Backdrop from '../Backdrop/Backdrop';

const loader = () => (
  <Backdrop>
    <div className="spinner-border loader" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </Backdrop>
);

export default loader;
