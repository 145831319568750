import React, {Component} from 'react';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';
import axios from 'axios';
import {CSVLink} from 'react-csv';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import {getDate, prepareEmotes} from '../../store/utility';
import * as actions from '../../store/actions';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
        };
    }

    componentDidMount() {
        this.source = axios.CancelToken.source();

        axios
            .get('/users', {
                cancelToken: this.source.token
            })
            .then(response => {
                this.setState({
                    users: response.data
                });
            });

    }

    render() {
        let users = null;
        let trialCount = 0;

        const csvToExport = [];
        const csvHeader = [
            'User Id',
            'Name',
            'Email',
            'Member Since',
            'Streak',
            'Status',
            'Words',
            'Words per day',
            'Days with emotes',
            'Days without emotes',
        ];
        csvToExport.push(csvHeader);

        if (this.state.users) {
            trialCount = this.state.users.length;
            users = this.state.users.map(user => {
                user.emotes = prepareEmotes(user.emotes);
                csvToExport.push([
                    user.id,
                    `${user.firstName} ${user.lastName}`,
                    user.email,
                    getDate(user.created_at, 1).date,
                    user.emotes.streak,
                    'Trial',
                    this.getWords(user.emotes.emotes),
                    parseInt(this.getWords(user.emotes.emotes) / this.dateDiffCalc(user.created_at)),
                    Object.keys(user.emotes.emotes).length,
                    this.dateDiffCalc(user.created_at) - Object.keys(user.emotes.emotes).length,
                ]);
                

                const words = parseInt(this.getWords(user.emotes.emotes) / this.dateDiffCalc(user.created_at))

                return (
                    <tr key={user.id}>
                        <td>
                            {user.firstName}
                            {' '}
                            {user.lastName}
                        </td>
                        <td>{user.email}</td>
                        <td>{getDate(user.created_at, 1).date}</td>
                        <td>{user.emotes.streak}</td>
                        <td className="text-warning">Trial</td>
                        <td>{this.getWords(user.emotes.emotes)}</td>
                        <td>{words || 0}</td>
                        <td>{Object.keys(user.emotes.emotes).length}</td>
                        <td>{this.dateDiffCalc(user.created_at) - Object.keys(user.emotes.emotes).length}</td>
                    </tr>
                );
            })
        }

        return (
            <div id="admin-dashboard">
                <h1>Users</h1>
                <div className="row" id="stats-buttons">
                    <div className="col-12">
                        <button className="btn btn-success">Total Paid: 0</button>
                        <button className="btn btn-warning">
                            Total Trials:
                            {trialCount}
                        </button>
                        <button className="btn btn-dark">Current Month’s Subscription: 0</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="table-container">
                            <table className="table" id="users">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Member Since</th>
                                    <th>Streak</th>
                                    <th>Status</th>
                                    <th>Words</th>
                                    <th>Words per day</th>
                                    <th>Days with emotes</th>
                                    <th>Days without emotes</th>
                                </tr>
                                </thead>
                                <tbody>
                                {users}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right">
                        <CSVLink
                            data={csvToExport}
                            filename={`Emote-Users-${parseInt(getDate().day)}-${getDate().monthName}.csv`}
                            className="btn btn-secondary btn-sm"
                            target="_blank"
                        >
                            Export List
                        </CSVLink>
                    </div>
                </div>
                {!this.props.user ? <Redirect to="/login"/> : null}
            </div>
        );
    }

    dateDiffCalc(date) {
        return Math.round((getDate().raw - getDate(date).raw) / (1000 * 60 * 60 * 24));
    }

    getWords(emotes) {
        let count = 0;
        for (const emote of Object.values(emotes)) {
            count += emote.words;
        }
        return count;
    }

    componentWillUnmount() {
        if (this.source) {
            this.source.cancel('Request cancelled');
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
    getUsers: () => dispatch(actions.getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Users, axios));
