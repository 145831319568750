import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import * as actions from '../../store/actions';
import {unformatTime} from "../../store/utility";
import IntentionList from "../../components/UI/IntentionList/IntentionList";
import {ToastsStore} from 'react-toasts';

class YourGoals extends Component {
    state = {
        words: 250,
        reminder: '8:00 am',
        intentionSaving: false,
        wordsSaving: false,
        reminderSaving: false
    };

    async componentDidMount() {
        if (this.props.user) {
            const reminder = this.props.user.reminder || this.state.reminder;
            this.setState({
                words: this.props.user.words,
                intention_id: this.props.user.intention_id || '',
                reminder: reminder.startsWith('0') ? reminder.substr(1, reminder.length) : reminder
            });
        }
    }

    updateUser = (property, loading) => {
        return () => {
            const value = this.state[property];

            this.setState({[loading]: true})

            axios.patch(`/current`, {[property]: property === 'reminder' ? unformatTime(value) : value})
                .then(async () => {
                    const data = {[property]: value};

                    if (property === 'intention_id') {
                        data.intention = this.state.intention;
                    }

                    await this.props.updateProfile(data);
                })
                .finally(() => {
                    if(property === 'intention_id' || property === 'words') {
                        ToastsStore.success("✓ Saved!");
                    }
                    setTimeout(() => {
                        this.setState({[loading]: false})
                    }, 500)
                });
        }
    };

    numberInputChange = async event => {
        const maxValue = 99999;
        const words = event.target.value.replace(/\D/, '');

        let value = '';
        if (words) {
            if (words >= 0 && words <= maxValue) {
                value = parseInt(words);
            } else if (words > maxValue) {
                value = maxValue;
            }
        }

        if (this.state.words !== value) {
            await this.setState({words: value})
        }
    };

    onSelectIntention = (intention) => {
        this.setState({
            intention,
            intention_id: intention.id
        })
    }

    inputChangeHandler = (event) => {
        const property = event.target.name;
        let value = event.target.value;

        this.setState({[property]: value})
    };

    render() {
        const minutesInterval = 15;
        const times = [];
        const ap = ['am', 'pm'];

        let startTime = 0;
        for (let i = 0; startTime < 24 * 60; i++) {
            const hours = Math.floor(startTime / 60);
            const minutes = (startTime % 60);
            const time = (hours % 12 === 0 ? 12 : hours % 12) + ':' + ("0" + minutes).slice(-2) + ' ' + ap[Math.floor(hours / 12)];
            times.push(<option key={time}>{time}</option>);
            startTime = startTime + minutesInterval;
        }

        const startDate = new Date();
        const [hours, minutes] = unformatTime(this.state.reminder).split(':')

        startDate.setHours(parseInt(hours))
        startDate.setMinutes(parseInt(minutes));

        const dateToString = () => {
            return startDate.getUTCFullYear() +
                (startDate.getUTCMonth() + 1).toString().padStart(2, '0') +
                startDate.getUTCDate().toString().padStart(2, '0') + 'T' +
                startDate.getUTCHours().toString().padStart(2, '0') +
                startDate.getUTCMinutes().toString().padStart(2, '0') + '00Z';
        }

        var event = {
            'text': 'It’s time to emote!',
            'location': 'https://app.writeemote.com/writing',
            'description': 'It\'s time to write something',
            'dates': dateToString(startDate) + '%2F' + dateToString(startDate.setMonth(startDate.getMonth() + 1)),
            'recur': 'RRULE:FREQ=DAILY'
        };

        let googleCalendarLink = 'https://calendar.google.com/calendar/render?action=TEMPLATE';

        for (let p in event) {
            googleCalendarLink += '&' + p + '=' + event[p]
        }

        return (
            <div id={"goals"}>
                <h3 className={"text-center"}>Set Your Goals</h3>
                <div className={"wrapper"}>
                    <div className={"box"}>
                        <div id={"weekly-intention"}>
                            <h2>Set your intention<i className={"icon icon-goal"}/></h2>
                            <IntentionList value={this.state.intention_id} onChange={this.onSelectIntention}/>
                            <button
                                onClick={this.updateUser('intention_id', 'intentionSaving')}
                                disabled={!this.state.intention_id || this.state.intentionSaving}
                                className="btn btn-primary">{this.state.intentionSaving ? 'Saving...' : 'Save'}
                            </button>
                            <small>This Will Curate Your Daily Prompt</small>
                        </div>
                    </div>
                    <div className={"boxes"}>
                        <div id={"words"}>
                            <div className={"box"}>
                                <h2>Word goal<i className={"icon icon-letters"}/></h2>
                                <input type="text"
                                       name="words"
                                       className={"form-control"}
                                       placeholder="e.g., 250"
                                       value={this.state.words}
                                       onChange={this.numberInputChange}/>

                                <button
                                    onClick={this.updateUser('words', 'wordsSaving')}
                                    disabled={!this.state.words || this.state.wordsSaving}
                                    className="btn btn-primary">{this.state.wordsSaving ? 'Saving...' : 'Save'}</button>
                            </div>
                        </div>
                        <div id={"reminder"}>
                            <div className={"box"}>
                                <h2>Daily<i className={"icon icon-bell"}/></h2>
                                <select name={"reminder"}
                                        value={this.state.reminder}
                                        onChange={this.inputChangeHandler}>{times}</select>

                                <a href={googleCalendarLink}
                                   onClick={this.updateUser('reminder', 'reminderSaving')}
                                   target={"_blank"} rel="noopener noreferrer"
                                   className={"btn btn-primary"}>{this.state.reminderSaving ? 'Saving...' : 'Send calendar invite'}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        if (this.source) {
            this.source.cancel('Request cancelled');
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
    updateProfile: (user) => dispatch(actions.updateProfile(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(YourGoals, axios));
