import React, {Component} from 'react';
import axios from "axios";

class IntentionList extends Component {
    state = {
        intention_id: null,
        intentions: []
    };

    async componentDidMount() {
        this.source = axios.CancelToken.source();

        try {
            const {data: intentions} = await axios.get('/intentions', {
                cancelToken: this.source.token
            });

            await this.setState({
                intentions,
                intention_id: this.props.value
            })
        } catch (e) {
        }
    }

    inputChangedHandler = event => {
        const value = parseInt(event.target.value);
        this.setState({intention_id: value})

        const selectedIntention = this.state.intentions.find(i => i.id === value);
        this.props.onChange(selectedIntention);
    };

    render() {
        let leftColumn = [];
        let rightColumn = [];

        if (Array.isArray(this.state.intentions)) {
            const half = Math.round(this.state.intentions.length / 2);
            leftColumn = this.state.intentions.slice(0, half).map(intention => {
                return <div className="form-check" key={intention.id}>
                    <input className="form-check-input" type="radio" name="intention_id"
                           onChange={(event) => this.inputChangedHandler(event)}
                           checked={this.state.intention_id === intention.id}
                           id={"intention" + intention.id} value={intention.id}/>
                    <label className="form-check-label" htmlFor={"intention" + intention.id}>
                        <span/>{intention.name}
                    </label>
                </div>
            })
            rightColumn = this.state.intentions.slice(half, this.state.intentions.length).map(intention => {
                return <div className="form-check" key={intention.id}>
                    <input className="form-check-input" type="radio" name="intention_id"
                           checked={this.state.intention_id === intention.id}
                           onChange={(event) => this.inputChangedHandler(event)}
                           id={"intention" + intention.id} value={intention.id}/>
                    <label className="form-check-label"
                           htmlFor={"intention" + intention.id}><span/>{intention.name}</label>
                </div>
            })
        }

        return (
            <div id={"checkboxes"}>
                <div className="column">{leftColumn}</div>
                <div className="column">{rightColumn}</div>
            </div>
        );
    }

    componentWillUnmount() {
        if (this.source) {
            this.source.cancel('Request cancelled');
        }
    }
}

export default IntentionList;
