import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  loading: false,
  error: null,
  // ToDo: add needed properties
};

const getPermissionsStart = (state, action) => updateObject(state, { loading: true });

const getPermissionsSuccess = (state, action) => {
  console.log(action);
  return updateObject(state, {
    loading: false,
    error: null,
    // ToDo: add needed properties
  });
};

const getPermissionsFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.type,
});

const permissions = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERMISSIONS_START: return getPermissionsStart(state, action);
    case actionTypes.GET_PERMISSIONS_SUCCESS: return getPermissionsSuccess(state, action);
    case actionTypes.GET_PERMISSIONS_FAIL: return getPermissionsFail(state, action);
    default: return state;
  }
};

export default permissions;
