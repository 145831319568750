import React, {Component,} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import {getDate} from '../../store/utility';
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import axios from "axios";
import Week from "../../components/Content/Calendar/Week";
import {Link} from "react-router-dom";

class Daily extends Component {
    state = {
        today: getDate(null, true).date,
        date: null,
        month: getDate().month - 1,
        year: getDate().year,
        emote: null,
        weeks: null,
        days: null
    };

    async componentDidMount() {
        await this.props.getUserEmotes();
        await this.prepareData(true);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            await this.prepareData();
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            await this.prepareData();
        }
    }

    async prepareData() {
        let date = this.props.location.pathname.replace('/past', '').replace('/', '');
        if (!date) {
            date = this.state.today
        } else {
            let d = await getDate(date, false, null, true);
            let month = d.month - 1;
            let year = d.year;
            this.setState({
                month: month,
                year: year
            })
        }
        let emote = this.props.emotes && this.props.emotes.emotes && this.props.emotes.emotes[date] ?
            this.props.emotes.emotes[date] : null;

        let empty = 6;
        let first = new Date(this.state.year, this.state.month, 1);
        if (first.getDay() > 0) {
            empty = first.getDay() - 1;
        }
        const total = getDate(new Date(this.state.year, this.state.month, 1)).days;
        const days = [];
        for (let i = 0; i < empty; i++) {
            days.push(null);
        }
        for (let i = 1; i <= total; i++) {
            days.push({
                number: i,
                today: getDate().day === i, // check year and month
                emote: this.props.emotes && this.props.emotes.emotes &&
                    !!this.props.emotes.emotes[getDate(new Date(this.state.year, this.state.month, i), true).date]
            });
        }
        let emptyPast = Math.ceil(days.length / 7) * 7 - days.length;
        for (let i = 0; i < emptyPast; i++) {
            days.push(null);
        }

        let weeks = [];
        var i, j, temparray, chunk = 7;
        for (i = 0, j = days.length; i < j; i += chunk) {
            temparray = days.slice(i, i + chunk);
            weeks.push(temparray);
        }
        await this.setState({
            date: date,
            emote: emote,
            days: days,
            weeks: weeks
        });
    }

    getPrevUrl() {
        const registered = new Date(this.props.user.register_date);
        const d = new Date(registered.getFullYear(), registered.getMonth(), 1);
        const month = this.state.month === 1 ? 12 : this.state.month - 1;
        const year = this.state.month === 1 ? this.state.year - 1 : this.state.year;
        const date = getDate(new Date(year, month, 1), true);
        return date.raw >= d ? date.date : false;
    }

    getNextUrl() {
        const month = this.state.month === 12 ? 1 : this.state.month + 1;
        const year = this.state.month === 12 ? this.state.year + 1 : this.state.year;
        return getDate(new Date(year, month, 1), true).date;
    }

    getMonthName() {
        return getDate(new Date(this.state.year, this.state.month, 1)).monthName;
    }

    isCurrentMonth() {
        return getDate(new Date(this.state.year, this.state.month, getDate().day)).date === getDate().date;
    }

    render() {
        let calendar = null;
        if (this.state.weeks) {
            calendar = (
                this.state.weeks.map((week, index) => {
                    return (<Week days={week} key={index} month={this.state.month} year={this.state.year}/>)
                })
            )
        }
        const style = this.state.emote && this.state.emote.style ? JSON.parse(this.state.emote.style.css) : {};
        let emote = this.state.emote ? (
            <div className="row" id={"past-emote"} style={style}>{this.state.emote.text}</div>) : null;
        let btn = this.state.emote ? (
            <Link className="btn btn-success btn-sm" to={`/${this.state.date}`}>Daily Insights</Link>) : null;
        let prompt = this.state.emote && this.state.emote.prompt ?
            this.state.emote.prompt.prompt : null;
        return (
            <div id="daily-analytics">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col text-center">
                            <h3>Past Emotes</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div id="calendar" className={emote ? 'hasEmote' : 'noEmote'}>
                            <div className="heading">
                                <div className="col-6 today"><b>Today,</b> {getDate().monthName} {getDate().day}th</div>
                                <div className="col-6 month">
                                    {this.getPrevUrl() ?
                                        <Link style={{cursor: 'pointer'}} to={'/past/' + this.getPrevUrl()}
                                              className="lnr lnr-chevron-left-circle ml-2 date-control-month "></Link> : null}
                                    &nbsp;
                                    {this.getMonthName()}, {this.state.year}
                                    {this.isCurrentMonth() ? null :
                                        <Link style={{cursor: 'pointer'}} to={'/past/' + this.getNextUrl()}
                                              className="lnr lnr-chevron-right-circle ml-1 date-control-month "></Link>}
                                </div>
                            </div>
                            <div className="columns days-titles">
                                <div className="row">
                                    <div className="col text-center">Mon</div>
                                    <div className="col text-center">Tue</div>
                                    <div className="col text-center">Wed</div>
                                    <div className="col text-center">Thu</div>
                                    <div className="col text-center">Fri</div>
                                    <div className="col text-center">Sat</div>
                                    <div className="col text-center">Sun</div>
                                </div>
                            </div>
                            <div className="columns days">
                                {calendar}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h1 id="today-prompt">{prompt}</h1>
                        <div className="col-12">{emote}</div>
                        <div className="text-center" style={{width: '100%', marginTop: '20px'}}>{btn}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        emotes: state.user.emotes
    }
};

const mapDispatchToProps = (dispatch) => ({
    getUserEmotes: () => dispatch(actions.getUserEmotes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Daily, axios));
