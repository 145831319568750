import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from "./hoc/Layout/Layout";
import Signup from "./containers/Signup/SignupForm";
import Home from "./containers/Home/Home";
import Daily from "./containers/Daily/Daily";
import Users from "./containers/Users/Users";
import Writing from "./containers/Writing/Writing";
import Login from "./containers/Login/Login";
import Reset from "./containers/Reset/Reset";
import NewPassword from "./containers/Reset/NewPassword";
import YourGoals from "./containers/YourGoals/YourGoals";
import {Redirect} from "react-router";

class App extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <Layout>
                {this.props.user ? (
                    // logged user
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/writing" component={Writing} />
                        <Route path="/past" component={Daily} />
                        <Route path="/goals" component={YourGoals} />
                        {this.props.user && this.props.user.isAdmin ? (<Route path="/users" exact component={Users} />) : (<Route path="/users" exact component={Home} />)}
                        <Route path="/login" exact component={Writing} />
                        <Route path="/signup" exact component={Writing} />
                        <Route path="/reset" exact component={Home} />
                        <Route path="/new-password" component={Home} />
                        <Route path='*' exact={true} component={Home} />
                    </Switch>
                ) : (
                    // NOT logged user
                    <Switch>
                        <Route path="/signup" exact component={Signup} />
                        <Route path="/login" exact component={Login} />
                        <Route path="/reset" exact component={Reset} />
                        <Route path="/new-password" component={NewPassword} />
                        <Route path='*'>
                            <Redirect to={"/login"} />
                        </Route>
                    </Switch>
                )}

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

export default withRouter(connect(mapStateToProps)(App));
