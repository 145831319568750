import React, {Component} from 'react';
import Aux from '../Auxiliary/Auxiliary';
import {withRouter} from "react-router";
import axios from "axios";
import {connect} from "react-redux";
import Sidebar from "./Sidebar";
import {Link} from "react-router-dom";

class Layout extends Component {
    state = {
        sidebarVisible: false
    }

    isWritingPage() {
        return this.props.location.pathname === '/writing';
    }

    toggleSidebar() {
        const state = !this.state.sidebarVisible;
        this.setState({sidebarVisible: state})
    }

    isLoggedUser() {
        return this.props.user && this.props.user.id;
    }

    onHideSidebar() {
        this.setState({sidebarVisible: false});
    }

    render() {
        let container = this.props.children;


        if (this.isWritingPage()) {
            container = <div className={"row"}>
                <div className={"col-12 animate__animated animate__fadeIn"}>{this.props.children}</div>
            </div>
        } else if (this.isLoggedUser()) {
            container =
                <div id={"content"} className={"container animate__animated animate__fadeIn"}>
                    {this.props.children}
                </div>;
        }

        return (
            <Aux>
                <header className={"navbar navbar-light"}>
                    <div className="container justify-content-center">
                        {this.isLoggedUser() ?
                            <button id={"menu-btn"} onClick={this.toggleSidebar.bind(this)}/> : ''}
                        <Link to='/' className="navbar-brand">Emote</Link>
                    </div>
                </header>
                <div id="main"
                     className={"container-fluid" +
                     (this.isLoggedUser() && this.state.sidebarVisible ? ' sidebar-visible' : '')}>
                    {this.isLoggedUser() ? <Sidebar user={this.props.user}
                                                    hide={this.onHideSidebar.bind(this)}
                                                    visible={this.state.sidebarVisible}/> : ''}
                    {container}
                </div>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

export default connect(mapStateToProps)(withRouter(Layout, axios));
