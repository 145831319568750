import React, {Component} from 'react';
import axios from 'axios';
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";

class Quote extends Component {
    state = {
        quote: ''
    };

    componentDidMount() {
        axios.get('/quote')
            .then(response => {
                this.setState({
                    quote: response.data
                })
            });
    }

    render() {
        return (
            <div id={"home-quote"}>
                <div>"{this.state.quote.quote}" - {this.state.quote.author}</div>
            </div>
        );
    }
}

export default withErrorHandler(Quote, axios);
