import React, {Component} from 'react';
import Day from "./Day";

class Week extends Component {

    render() {
        let week = null;
        if (this.props.days) {
            week = (
                this.props.days.map((day, index) => {
                    return (<Day day={day} key={index} month={this.props.month} year={this.props.year} />)
                })
            )
        }

        return (
            <div className="row">
                {week}
            </div>
        )

    }
}
export default Week;