import React, {Component} from "react";
import IntentionList from "../../../components/UI/IntentionList/IntentionList";

class Intention extends Component {
    state = {
        intention_id: null
    };

    componentDidMount() {
        if (this.props.user && this.props.user.intention_id) {
            this.setState({intention_id: this.props.user.intention_id})
        }
    }

    onChange = (intention) => {
        this.setState({intention_id: intention ? intention.id : null})
    }

    submit = () => {
        this.props.handleChange({intention_id: this.state.intention_id});
    };

    render() {
        return (
            <div className="step" id="intention">
                <h2 className="text-center">Set your intention</h2>
                <div className="text-center">
                    <small>(Why are you journaling today? We will use this to curate daily writing
                        prompts for you.)</small>
                </div>
                <IntentionList value={this.state.intention_id} onChange={this.onChange}/>
                <button
                    type={"button"}
                    onClick={this.submit}
                    disabled={!this.state.intention_id}
                    className="btn btn-danger text-uppercase d-block signup-button">
                    Next
                </button>
                <div id="quote">
                    <span className="icon icon-head" />
                    <small>Journaling is like whispering to one's self<br />and listening at the same time.</small>
                    <b>- Mina Murray</b>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        if (this.source) {
            this.source.cancel('Request cancelled');
        }
    }
}

export default Intention;
