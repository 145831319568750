import 'jquery';
import 'bootstrap';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import axios from 'axios';

import './assets/css/app.scss';
import App from './App';
import store from './store/store';
import {authLogout} from "./store/actions/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL.trim() : 'https://app.writeemote.com/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use((config) => {
    const obj = localStorage.getItem('user');
    const user = JSON.parse(obj);
    if (user && user.token) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
});

const {dispatch} = store;
axios.interceptors.response.use(response => response, error => {
    const {status} = error.response;

    if (status === 401) {
        dispatch(authLogout())
    }

    return Promise.reject(error);
})

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
