import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

let userObject;
if (localStorage.getItem('user')) {
    userObject = JSON.parse(localStorage.getItem('user'));
}

const initialState = {
    loading: false,
    user: userObject
};

const authStart = (state) => {
    return updateObject(state, {loading: true});
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        user: action.user
    });
};

const setProfileSuccess = (state, action) => {
    return {
        loading: false,
        error: null,
        user: action.user
    }
};

const authFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.type
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        user: null
    });
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.SET_PROFILE:
            return setProfileSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        default:
            return state;
    }
};

export default authReducer;
