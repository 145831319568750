import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  loading: false,
  error: null,
  // ToDo: add needed properties
};

const getRolesStart = (state, action) => updateObject(state, { loading: true });

const getRolesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    // ToDo: add needed properties
  });
};

const getRolesFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.type,
});

const roles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ROLES_START: return getRolesStart(state, action);
    case actionTypes.GET_ROLES_SUCCESS: return getRolesSuccess(state, action);
    case actionTypes.GET_ROLES_FAIL: return getRolesFail(state, action);
    default: return state;
  }
};

export default roles;
