import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {withRouter} from "react-router-dom";
import * as actions from "../../store/actions";
import {connect} from "react-redux";

class Sidebar extends Component {

    getUserInitials() {
        return this.props.user && this.props.user.id ? this.props.user.firstName[0] + this.props.user.lastName[0] : '';
    }

    onClick = () =>  {
        if (window.innerWidth < 992) {
            this.onHide()
        }
    }

    onHide = () => {
        this.props.hide();
    }

    onLogout = () => {
        this.onHide();
        this.props.onLogout();
    }

    render() {
        let location = this.props.location.pathname;

        return (
            <div id={"sidebar"} className={this.props.visible ? 'visible' : ''}>
                <div className={"profile-photo"}>
                    <div className={"photo-wrapper"}>{this.getUserInitials()}</div>
                    {/*<button>update photo</button>*/}
                </div>
                <ul className="navbar">
                    <li className={"nav-item " + (location === '/' ? 'active' : '')}>
                        <Link to="/" className="nav-link" onClick={this.onClick}>Home</Link>
                    </li>
                    <li className={"nav-item " + (location === '/writing' ? 'active' : '')} onClick={this.onHide}>
                        <Link to="/writing" className="nav-link">Today's Emote</Link>
                    </li>
                    <li className={"nav-item " + (location.includes('/past') ? 'active' : '')}>
                        <Link to="/past" className="nav-link" onClick={this.onClick}>Past Emotes</Link>
                    </li>
                    <li className={"nav-item " + (location === '/goals' ? 'active' : '')}>
                        <Link to="/goals" className="nav-link" onClick={this.onClick}>Set Your Goals</Link>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <Link to="/" onClick={context.showSidebar} className="nav-link">Trend Analysis</Link>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <Link to="/" onClick={context.showSidebar} className="nav-link">Recommendations</Link>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <Link to="/" onClick={context.showSidebar} className="nav-link">Account</Link>*/}
                    {/*</li>*/}
                    {this.props.user && this.props.user.isAdmin ?
                        <li className={"nav-item " + (location === '/users' ? 'active' : '')} onClick={this.onClick}>
                            <Link to="/users" className="nav-link">Member Management</Link>
                        </li> : null}
                    <li className="nav-item">
                        <button onClick={this.onLogout} className="nav-link">Log Out</button>
                    </li>
                </ul>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};

export default connect(null, mapDispatchToProps)(withRouter(Sidebar));
