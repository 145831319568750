import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import axios from 'axios';
import $ from 'jquery';
import Confetti from 'react-confetti';
import * as actions from '../../store/actions';

import '../../assets/css/writing-fonts.scss';
import StyleSelect from "../../components/UI/StyleSelect/StyleSelect";

class Writing extends Component {
    timer = 0;

    constructor(props) {
        super(props);
        this.state = {
            prompt: null,
            prompts: [],
            text: '',
            style: null,
            styles: [],
            writing: true,
            error: null,
            id: null,
            emoteId: null,
            progress: 0,
            backupTime: 0,
            monthBackup: '',
            wordsTarget: 0,
            done: null
        };
    }

    async componentDidMount() {
        await this.props.getUserEmotes();
        this.source = axios.CancelToken.source();

        try {
            if (this.props.user.intention_id) {
                const {data: prompts} = await axios.get('/intention-prompts?intention_id=' + this.props.user.intention_id, {
                    cancelToken: this.source.token
                });

                this.setState({prompts});
            }

            const {data: styles} = await axios.get('/emote-styles', {
                cancelToken: this.source.token,
            });

            this.setState({styles});
        } catch (e) {
        }

        this.startTimer();

        if (this.inputElement) {
            this.inputElement.focus();
        }
    }

    startTimer() {
        if (this.interval) {
            clearInterval(this.interval)
        }

        this.interval = setInterval(() => {
            if (this.state.writing) {
                this.timer = this.timer + 1;
            }
        }, 1000);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.emotes && !this.state.prompt) {
            const dates = Object.keys(this.props.emotes.emotes);
            const lastEmoteDate = dates && dates[0] ? dates[0] : null;
            const lastEmote = this.props.emotes.emotes[lastEmoteDate];

            if (this.props.emotes.today && !this.state.emoteId) {
                const todayEmote = this.props.emotes.today;

                if (todayEmote.timespent) {
                    this.timer = todayEmote.timespent;
                }

                await this.setState({
                    text: todayEmote.text,
                    style: todayEmote.style,
                    prompt: todayEmote.prompt,
                    emoteId: todayEmote.id,
                    done: todayEmote.done ? todayEmote.done : false,
                    wordsTarget: this.props.user.words || todayEmote.words
                })

                this.startTimer();

                this.countWords();
            } else if (lastEmote && lastEmote.prompt) {
                const prompts = this.state.prompts.sort((a, b) => a.id - b.id);
                const promptFound = prompts.find(p => p.id === lastEmote.prompt.id);

                let todayPrompt;
                if (promptFound && prompts.indexOf(promptFound) === prompts.length - 1) {
                    todayPrompt = prompts[0];
                } else if (prompts[prompts.indexOf(promptFound) + 1]) {
                    todayPrompt = prompts[prompts.indexOf(promptFound) + 1];
                }

                if (todayPrompt) {
                    await this.setState({prompt: todayPrompt})
                }
            }

            if (this.props.user.intention_id && !this.state.prompt) {
                if (this.state.prompts && this.state.prompts[0]) {
                    await this.setState({prompt: this.state.prompts.sort((a, b) => a.id - b.id)[0]})
                }
            }
        }
    }

    emoteTextChanged = async (event) => {
        await this.setState({
            text: event.target.value,
        });

        this.countWords();
        await this.saveEmote();
    };

    styleChanged = (style) => {
        if (style) {
            this.setState({style});
        } else {
            this.setState({style: null});
        }
    }

    countWords = () => {
        if (!this.state.text) {
            return 0;
        }
        const text = this.state.text.match(/[\w\d’'-]+/gi);
        const words = text ? text.length : 0;
        const plan = this.state.id ? this.state.wordsTarget : this.props.user.words ? this.props.user.words : 0;
        let result;

        if (!plan || words > plan) {
            result = 100;
        } else {
            result = Math.floor(words / plan * 100);
        }

        this.setState({
            words,
            progress: result,
        });
    };

    saveEmote = async (done, callback) => {
        if (this.isSaving && !this.state.emoteId) {
            return false;
        }

        this.isSaving = true;

        const data = {
            words: this.state.words ? this.state.words : 0,
            text: this.state.text,
            style_id: this.state.style ? this.state.style.id : null,
            timespent: this.timer,
            target: this.props.user.words,
            prompt_id: this.state.prompt ? this.state.prompt.id : null,
            done: (done || this.state.done) ? 1 : 0
        };

        if (this.state.emoteId) {
            axios.put('/emotes/' + this.state.emoteId, data)
                .finally(() => {
                    this.isSaving = false;

                    callback && callback();
                });
        } else {
            data.created_at = new Date().toUTCString();
            data.user_id = this.props.user.id;
            axios.post('/emotes', data).then((res) => {
                this.setState({
                    emoteId: res.data.id
                })
            }).finally(() => {
                this.isSaving = false;

                callback && callback();
            });
        }
    }

    endSession = async () => {
        await this.setState({
            writing: false
        })

        this.showConfirmation();

        this.saveEmote(1, async () => {
            await this.props.getUserEmotes();
            setTimeout(() => {
                this.hideConfirmation();
                window.location.replace('/');
            }, 4000);
        });
    };

    showConfirmation() {
        $('#root').addClass('blur');

        const $modal = $('#congratsModal');
        $modal.modal('show');
        $modal.appendTo('body');
    }

    hideConfirmation() {
        $('#root').removeClass('blur');

        const $modal = $('#congratsModal');
        $modal.hide('show');
        $modal.remove();
    }

    render() {
        const style = this.state.style ? JSON.parse(this.state.style.css) : {};

        let styleSelect;
        if (this.state.styles && this.state.styles.length) {
            styleSelect = (
                <StyleSelect
                    value={this.state.style}
                    styles={this.state.styles}
                    onChange={this.styleChanged}>
                </StyleSelect>
            );
        }

        const opacity = this.state.progress ? this.state.progress / 100 : 0;

        let progress;

        if (this.state.progress >= 0) {
            if (this.state.progress < 100) {
                progress = (
                    <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{width: `${this.state.progress}%`}}
                        aria-valuenow={this.state.progress}
                        aria-valuemin="0"
                        aria-valuemax="100">
              <span className={`percentage${this.state.progress < 30 ? ' outer' : ''}`}>
                {this.state.progress || 0}
                  %
              </span>
                    </div>
                );
            } else {
                progress = `${this.state.words} Words`;
            }
        }

        return (
            <div className="row" id="writing-page">
                <div className="grass">
                    <div className="gray" style={{opacity: 1 - opacity}}/>
                    <div className="colored" style={{opacity}}/>
                </div>

                <div className="background left">
                    <div className="gray" style={{opacity: 1 - opacity}}/>
                    <div className="colored" style={{opacity}}/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 id="today-prompt"
                                className="text-center">{this.state.prompt ? this.state.prompt.prompt : ''}</h1>
                            <textarea
                                style={style}
                                readOnly={this.state.id}
                                id="emote-text"
                                placeholder="Start typing..."
                                ref={(inputEl) => {
                                    this.inputElement = inputEl;
                                }}
                                onChange={(event) => this.emoteTextChanged(event)}
                                value={this.state.text}
                                autoFocus
                            />
                        </div>
                    </div>

                    <div className="row" id="controls">
                        <div className="col-4">
                            {styleSelect}
                        </div>
                        <div className="col-4 text-center">
                            <button
                                onClick={this.endSession}
                                disabled={!this.state.text}
                                className="btn btn-outline-danger text-uppercase animate__animated animate__zoomIn"
                            >
                                End
                                Session
                            </button>
                        </div>
                        <div className="col-4 justify-content-end">
                            <div className={`progress${this.state.progress === 100 ? ' complete' : ''}`}>
                                {progress}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="background right">
                    <div className="gray" style={{opacity: 1 - opacity}}/>
                    <div className="colored" style={{opacity}}/>
                </div>

                <div className="modal fade" id="congratsModal" tabIndex="-1" role="dialog" aria-labelledby="congratsModal" aria-hidden="true">
                    <Confetti width={window.innerWidth} height={window.innerWidth}/>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body"/>
                            <div className="modal-footer">
                                <h1 className="uppercase text-purple">CONGRATULATIONS!</h1>
                                <p>You've completed today's Emote.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.user ? <Redirect to="/login"/> : null}
            </div>
        );
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.source) {
            this.source.cancel('Request cancelled');
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    emotes: state.user.emotes
});

const mapDispatchToProps = (dispatch) => ({
    getUserEmotes: () => dispatch(actions.getUserEmotes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Writing);
