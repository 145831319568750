import React, {Component} from "react";

class WordGoal extends Component {
    state = {
        words: ''
    };

    componentDidMount() {
        if (this.props.user && this.props.user.words) {
            this.setState({words: this.props.user.words})
        }

        this.inputElement.focus()
    }

    submit = (event) => {
        event.preventDefault()
        this.props.handleChange({words: this.state.words});
    };

    inputChangedHandler = event => {
        const words = event.target.value.replace(/\D/,'');

        if (words && !isNaN(words)) {
            if (words <= 99999) {
                this.setState({words: parseInt(words)})
            }
        } else {
            this.setState({words: ''})
        }
    };

    render() {
        return (
            <div className="step" id="word-goal">
                <h2 className="text-center">Set your word goal</h2>
                <div className="text-center">
                    <small>(Most people start at 250 words per day. That’s approximately 5 minutes of writing. You can change your target at any point.)</small>
                </div>
                <div className="form-group">
                    <input type="text"
                           name="words"
                           className={"form-control " + (this.state.validation && this.state.validation.words ? 'error' : '')}
                           placeholder="e.g., 250"
                           ref={(inputEl) => {
                               this.inputElement = inputEl
                           }}
                           value={this.state.words}
                           onChange={this.inputChangedHandler}
                           onInput={this.inputChangedHandler}/>
                </div>
                <div className="form-group submit">
                    <button
                        type="button"
                        onClick={this.submit}
                        className="btn btn-danger text-uppercase d-block signup-button"
                        disabled={!this.state.words}>
                        Next
                    </button>
                </div>
                <div id="quote">
                    <span className="icon icon-aim" />
                    <small>Write what disturbs you, what you fear, what you<br/>have not been willing to speak about. Be willing to be<br/>split open. <b>- Natalie Goldberg</b></small>
                </div>
            </div>
        )
    }
}

export default WordGoal;
