import React, {Component} from 'react';
import '../../../assets/css/style-select.scss';

class StyleSelect extends Component {
    state = {
        styles: [],
        style: null,
        visible: false
    }

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)

        if (!this.state.style) {
            if (this.props.value) {
                await this.setState({style: this.props.value})
            } else if (this.props.styles) {
                await this.setState({style: this.props.styles[0]})
            }
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && (!this.wrapperRef.current || !this.wrapperRef.current.contains(event.target))) {
            this.hide();
        }
    }

    onSelectStyle = (style) => {
        this.setState({style});
        this.props.onChange(style);
    }

    toggle = () => {
        this.setState({visible: !this.state.visible})
    }

    hide = () => {
        this.setState({visible: false})
    }

    render() {
        let list;

        if (Array.isArray(this.props.styles)) {
            list = <ul id={"options"} className={(this.state.visible ? 'visible' : '')}>
                {this.props.styles.map(
                    style => <li onClick={() => this.onSelectStyle(style)}
                                 key={style.id}
                                 className={(this.state.style && this.state.style.id === style.id ? 'selected' : '')}
                                 style={style.css ? JSON.parse(style.css) : {}}>{style.name}</li>)
                }
            </ul>;
        }

        return (<div id={"style-select"} onClick={this.toggle} ref={this.wrapperRef}>
            <label htmlFor={"style-select"}
                   style={this.state.style ? JSON.parse(this.state.style.css) : {}}>{this.state.style ? this.state.style.name : 'Choose'}</label>
            {list}
        </div>);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)

        if (this.source) {
            this.source.cancel('Request cancelled');
        }
    }
}

export default StyleSelect;
