import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Loader from '../../components/UI/Loader/Loader';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

class Reset extends Component {
    state = {
        username: '',
        error: '',
        loading: false,
        validation: null
    };

    componentDidMount() {
        this.inputElement.focus();
    }

    nameChangedHandler = (event) => {
        this.setState({
            ...this.state,
            username: event.target.value,
        });
    };

    reset = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            loading: true,
        });
        axios.get(`/reset-password?email=${this.state.username}`)
            .then((response) => {
                this.setState({success: true, loading: false});
            })
            .catch((err) => {
                this.setState({
                    ...this.state,
                    error: err.response.data.message,
                    validation: err.response.data.errors,
                    loading: false,
                });
            }); // ToDo: move to actions
    };

    render() {
        const success = this.state.success ?
            <div className="alert alert-success" role="alert">A password change request has been sent to the email address you provided. Check your inbox or spam folder.</div> : null;

        const error = this.state.error ?
            <div className="alert alert-danger" role="alert">{this.state.error}</div> : null;

        return (
            <div className="row justify-content-center align-items-center" id="sign-page">
                <div className="card sign-in-card animate__animated animate__fadeIn">
                    <h1 className="text-center">Reset Password</h1>
                    {success}
                    <form onSubmit={this.reset}>
                        <div className="form-group">
                            <input
                                type="email"
                                name="username"
                                ref={(inputEl) => {
                                    this.inputElement = inputEl;
                                }}
                                className={`form-control ${this.state.validation && this.state.validation.email ? 'error' : ''}`}
                                placeholder="Enter email"
                                onChange={this.nameChangedHandler}
                                value={this.state.username}
                            />
                        </div>
                        {error}
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-danger text-uppercase d-block login-button"
                                disabled={!this.state.username || this.state.success}
                            >Reset</button>
                        </div>
                        <div className="form-group justify-content-between settings-links">
                            <Link to="/login" className="text-darkblue back-button">Back to Login</Link>
                            {' '}
                            <br/>
                        </div>
                    </form>
                </div>
                {this.state.loading ? <Loader/> : null}
                {/* ToDo: move loader to layout */}
            </div>
        );
    }
}

export default withErrorHandler(Reset, axios);
