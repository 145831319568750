import React, {Component} from "react";

class Start extends Component {
    redirectToWriting = () => {
        this.props.history.push('/writing');
    }

    submit = () => {
        this.props.complete();
    }

    render() {
        return (
            <div className="step" id="start">
                <h2 className="text-center">Are you ready for your first prompt?</h2>
                <ul>
                    <li>- Just start writing, anything that comes to mind</li>
                    <li>- Don't look back, no need to edit yourself</li>
                    <li>- Be authentic, your journal is judgement-free</li>
                </ul>
                <div className="form-group submit">
                    <button
                        onClick={this.submit}
                        className="btn btn-danger text-uppercase d-block signup-button">
                        Begin the journey
                    </button>
                </div>
                <div id="quote">
                    <span className="icon icon-man-with-backpack"/>
                    <small>People who keep journals have life twice</small>
                    <b className="d-block">- Jessamyn West</b>
                </div>
            </div>
        )
    }
}

export default Start;
