import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    loading: false,
    error: null
};

const getUserStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const getUserSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: null
    } );
};

const getUserFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.type
    } );
};

const getUserEmotesStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const getUserEmotesSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: null,
        emotes: action.emotes
    } );
};

const getUserEmotesFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.type
    } );
};

const getUsersStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const getUsersSuccess = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: null,
        users: action
    } );
};

const getUsersFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.type
    } );
};

const user = (state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_USER_START: return getUserStart( state, action );
        case actionTypes.GET_USER_SUCCESS: return getUserSuccess( state, action );
        case actionTypes.GET_USER_FAIL: return getUserFail( state, action );
        case actionTypes.GET_USER_EMOTES_START: return getUserEmotesStart( state, action );
        case actionTypes.GET_USER_EMOTES_SUCCESS: return getUserEmotesSuccess( state, action );
        case actionTypes.GET_USER_EMOTES_FAIL: return getUserEmotesFail( state, action );
        case actionTypes.GET_USERS_START: return getUsersStart( state, action );
        case actionTypes.GET_USERS_SUCCESS: return getUsersSuccess( state, action );
        case actionTypes.GET_USERS_FAIL: return getUsersFail( state, action );
        default: return state;
    }
};

export default user;
