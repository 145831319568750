import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

class Signup extends Component {
    state = {
        id: null,
        password: '',
        email: '',
        firstName: '',
        lastName: '',
        loading: false,
        validation: null,
        hidden: true
    };

    componentDidMount() {
        this.inputElement.focus();

        if (this.props.user) {
            this.setState({...this.props.user})
        }

        if (this.props.validation) {
            this.setState({validation: {...this.props.validation}})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.validation && !this.state.validation) {
            this.setState({validation: {...this.props.validation}})
        }
    }

    toggleInput = () => {
        this.setState({hidden: !this.state.hidden})
    }

    inputChangedHandler = event => {
        const {name, value} = event.target
        this.setState({[name]: value}, () => {
            this.validateField(name, value)
        })
    };

    validateField = (name, value) => {
        let validation = this.state.validation;

        switch (name) {
            case 'email':
            case 'password':
            case 'lastName':
            case 'firstName':
                if (!value) {
                    validation = validation || {};
                    validation[name] = 'Field cannot be empty';
                } else if (name === 'password' && value && !value.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+~])[A-Za-z\d!@#$%^&*()_+~]{8,}$/)) {
                    validation = validation || {};
                    validation['password'] = 'Password should contain at least 8 characters, 1 special symbol, 1 number and one uppercase';
                } else if (validation && validation[name]) {
                    delete validation[name];
                }
                break;
            default:
                break;
        }

        this.setState({validation});
    }

    isFormValid() {
        return !this.state.validation || Object.keys(this.state.validation).length === 0;
    }

    checkEmail() {
        this.setState({loading: true})
        return axios
            .get('/check-email?email=' + this.state.email)
            .finally(() => {
                this.setState({loading: false})
            })
            .catch((error) => {
                this.setState({
                    validation: {
                        email: 'This email address already in use'
                    }
                })
            });
    }

    submit = event => {
        event.preventDefault();

        this.checkEmail().then(() => {
            if (this.isFormValid()) {
                this.setState({validation: null})

                this.props.handleChange({
                    lastName: this.state.lastName,
                    firstName: this.state.firstName,
                    password: this.state.password,
                    email: this.state.email,
                });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <h1 className="text-center">Sign Up</h1>
                <form onSubmit={this.submit} autoComplete="off">
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <input type="text"
                                       name="firstName"
                                       className={"form-control " + (this.state.validation && this.state.validation.firstName ? 'error' : '')}
                                       placeholder="First Name"
                                       ref={(inputEl) => {
                                           this.inputElement = inputEl
                                       }}
                                       onChange={(event) => this.inputChangedHandler(event)}
                                       value={this.state.firstName}
                                />
                                {this.state.validation && this.state.validation.firstName ?
                                    <span className="form-error">{this.state.validation.firstName}</span> : ''}
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="form-group">
                                <input type="text"
                                       name="lastName"
                                       className={"form-control " + (this.state.validation && this.state.validation.lastName ? 'error' : '')}
                                       placeholder="Last Name"
                                       onChange={(event) => this.inputChangedHandler(event)}
                                       value={this.state.lastName}
                                />
                                {this.state.validation && this.state.validation.lastName ?
                                    <span className="form-error">{this.state.validation.lastName}</span> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <input type="text"
                               name="email"
                               className={"form-control " + (this.state.validation && this.state.validation.email ? 'error' : '')}
                               placeholder="Email"
                               onChange={(event) => this.inputChangedHandler(event)}
                               value={this.state.email}/>
                        {this.state.validation && this.state.validation.email ?
                            <span className="form-error">{this.state.validation.email}</span> : ''}
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input type={this.state.hidden ? "password" : "text"}
                                   name="password"
                                   className={"form-control " + (this.state.validation && this.state.validation.password ? 'error' : '')}
                                   placeholder="Password"
                                   onChange={(event) => this.inputChangedHandler(event)}
                                   value={this.state.password}
                            />
                            <div className="input-group-append">
                                <i className="icon icon-show-password" onClick={this.toggleInput}/>
                            </div>
                        </div>
                        {this.state.validation && this.state.validation.password ?
                            <span className="form-error">{this.state.validation.password}</span> : ''}
                    </div>
                    <div className="form-group submit">
                        <button
                            type="submit"
                            className="btn btn-danger text-uppercase d-block signup-button"
                            disabled={this.state.loading || !this.state.email || !this.state.firstName || !this.state.lastName || !this.state.password || !this.isFormValid()}>
                            Next
                        </button>
                    </div>
                    <div className="text-center pt-3">
                        Already have an account?&nbsp;<Link to="/login" className="back-button">Log In</Link>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

export default Signup;
