import * as actionTypes from './actionTypes';
import axios from 'axios';
import {prepareEmotes} from '../utility';
import { logout } from './auth';


export const getUserStart = () => {
    return {
        type: actionTypes.GET_USER_START
    };
};

export const getUserFail = (error) => {
    return {
        type: actionTypes.GET_USER_FAIL,
        error: error
    };
};

export const getUserEmotesStart = () => {
    return {
        type: actionTypes.GET_USER_EMOTES_START
    };
};

export const getUserEmotesSuccess = (emotes) => {
    return {
        type: actionTypes.GET_USER_EMOTES_SUCCESS,
        emotes: emotes
    };
};

export const getUserEmotesFail = (error) => {
    return {
        type: actionTypes.GET_USER_EMOTES_FAIL,
        error: error
    };
};

export const getUserEmotes = () => {
    return dispatch => {
        dispatch(getUserEmotesStart());
        axios.get(`/emotes`)
            .then(response => {
                dispatch(getUserEmotesSuccess(prepareEmotes(response.data)));
            })
            .catch(err => {
                dispatch(getUserEmotesFail(err));
            })
    };
};

export const getUsersStart = () => {
    return {
        type: actionTypes.GET_USERS_START
    };
};

export const getUsersSuccess = (users) => {
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        users: users
    };
};

export const getUsersFail = (error) => {
    return {
        type: actionTypes.GET_USERS_FAIL,
        error: error
    };
};

export const getUsers = () => {
    return dispatch => {
        dispatch(getUsersStart());
        axios.get('/users')
            .then(response => {
                let users = response.data;
                users = users.map((user) => {
                    return {
                        ...user,
                        emotes: prepareEmotes(user.emotes)
                    }
                });
                dispatch(getUsersSuccess(users));
            })
            .catch(err => {
                dispatch(getUsersFail(err));
                if (err.response.status === 401) {
                    dispatch(logout());
                }
            })
    };
};
