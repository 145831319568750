import React, {Component} from 'react';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import Aux from '../Auxiliary/Auxiliary';

const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {
        UNSAFE_componentWillMount() {

            this.reqInterceptor = axios.interceptors.request.use(request => {
                return request;
            }, error => {
                ToastsStore.error(error.message, 5000);
                return Promise.reject(error);
            });

            this.resInterceptor = axios.interceptors.response.use(response => {
                return response;
            }, error => {
                let errorText = '';
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const property in error.response.data.errors) {
                        if (error.response.data.errors.hasOwnProperty(property)) {
                            errorText += error.response.data.errors[property].message + '. ';
                        }
                    }

                    //Birthday error
                    if (errorText.includes("birthday")) errorText = "The birthday date is invalid";

                    // Email error
                    if (error.response.data.errors.email) {
                        errorText = "The Email address is not valid or already taken";
                    }

                    //Username error
                    if (error.response.data.errors.username) {
                        errorText = "The Username is not valid or already taken";
                    }
                } else if (error.response && error.response.data) {
                    //Reset password error
                    if (error.response.data === "username is not defined") errorText = "The email address or username is incorrect.";

                    //Login error
                    if (error.response.data.message) {
                        //Login failed
                        if (error.response.data.message === "Unauthorized") errorText = "The email address or password is incorrect."
                    }

                    if (error.response.data.indexOf('Validation error: ') !== -1) {
                        errorText = error.response.data.replace('Validation error: ', '')
                    }
                } else {
                    //Unhandled
                    errorText = "Error happened during request. Check your Internet connection";
                }

                if (!error.__CANCEL__) {
                    ToastsStore.error(errorText, 5000);
                }
                return Promise.reject(error);
            });
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        render() {
            return (
                <Aux>
                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT}/>
                    <WrappedComponent {...this.props} />
                </Aux>
            );
        }
    }
};

export default withErrorHandler;
