import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Loader from '../../components/UI/Loader/Loader';
import * as actions from "../../store/actions/index";
import axios from 'axios';

class Login extends Component {
    state = {
        username: '',
        password: '',
        hidden: true
    };

    componentDidMount() {
        this.inputElement.focus();
    }

    inputChangedHandler = (event, identifier) => {
        let updatedState = {...this.state};
        updatedState[identifier] = event.target.value;
        this.setState(updatedState);
    };

    login = (event) => {
        event.preventDefault();

        axios
            .post('/sign-in', {username: this.state.username, password: this.state.password})
            .then(async (response) => {
                if (response && response.data) {
                    this.props.authorize(response.data);
                }
            })
            .catch((error) => {
                let message;
                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message;
                } else {
                    message = 'Something went wront.'
                }

                this.setState({validation: message})
            });
    };

    toggleShow = () => {
        this.setState({
            ...this.state,
            hidden: !this.state.hidden
        });
    };

    render() {
        return (
            <div className="row justify-content-center align-items-center" id="sign-page">
                <div className="card sign-in-card">
                    <h1 className="text-center">Log in</h1>
                    {this.state.validation ? <div className="login-error">{this.state.validation}</div> : ''}
                    <form onSubmit={this.login}>
                        <div className="form-group">
                            <input type="text"
                                   name="username"
                                   ref={(inputEl) => {
                                       this.inputElement = inputEl
                                   }}
                                   className={"form-control " + (this.state.validation ? 'error' : '')}
                                   placeholder="Username"
                                   onChange={(event) => this.inputChangedHandler(event, 'username')}
                                   value={this.state.username}
                            />
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <input type={this.state.hidden ? "password" : "text"}
                                       name="password"
                                       className={"form-control " + (this.state.validation ? 'error' : '')}
                                       placeholder="Password"
                                       onChange={(event) => this.inputChangedHandler(event, 'password')}
                                       value={this.state.password}
                                />
                                <div className="input-group-append">
                                    <i className="icon icon-show-password" onClick={this.toggleShow}/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group justify-content-end settings-links">
                            <Link to="/reset" className="text-darkblue">Forgot Password?</Link> <br/>
                            {/*<a href="" className="text-purple">Set Up Two Factor Authentication</a>*/}
                        </div>
                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-danger text-uppercase d-block login-button"
                                disabled={!this.state.password || !this.state.username}
                            >Log in
                            </button>
                        </div>

                        <p className="row signup-link justify-content-center">New to Emote?
                            <Link to="/signup">Sign up for free</Link></p>
                    </form>
                </div>
                {this.props.loading ? <Loader/> : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authorize: (user) => dispatch(actions.authorize(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
