import React, {Component} from 'react';
import {connect} from "react-redux";

class Streak extends Component {
    render() {
        return (
            <div id={"streak"}>
                <i className="icon icon-stars"/> {this.props.streak ? this.props.streak : 0} Day Streak
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        streak: state.user && state.user.emotes && state.user.emotes.streak ? state.user.emotes.streak : 0
    }
};

export default connect(mapStateToProps, null)(Streak);
