import React, {Component} from 'react';
import axios from 'axios';

import 'react-datepicker/dist/react-datepicker.css';
import './SignupForm.scss';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import Signup from './Steps/Signup';
import Intention from './Steps/Intention';
import WordGoal from './Steps/WordGoal';
import Start from './Steps/Start';

const SIGNUP_STEP = 1;
const INTENTION_STEP = 2;
const WORD_GOAL_STEP = 3;
const START_STEP = 4;

class SignupForm extends Component {
    state = {
        currentStep: null,
        loading: false,
        validation: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user && this.props.user.id && !this.state.user) {
            this.setState({user: this.props.user});
        }
    }

    nextStep = () => {
        if (this.state.currentStep !== START_STEP) {
            const nextStep = (this.state.currentStep || 1) + 1;
            this.setState({currentStep: nextStep});
        }
    }

    previousStep = () => {
        if (this.state.currentStep !== SIGNUP_STEP) {
            this.setState({currentStep: this.state.currentStep - 1});
        }
    }

    selectStep(step) {
        if (!this.props.user || step === this.state.currentStep) {
            return false;
        }

        this.setState({currentStep: step});
    }

    handleChange = (user) => {
        this.setState({validation: null, user: {...this.state.user, ...user}}, () => {
            if (this.state.complete) {
                this.complete();
            } else {
                this.nextStep();
            }
        });
    }

    complete = () => {
        const data = this.state.user;

        this.setState({validation: null});

        axios.post('/sign-up', data)
            .then(async (response) => {
                if (response.data) {
                    this.props.authorize(response.data);
                }
            })
            .catch((error) => {
                const {errors} = error.response.data;

                if (errors.email || errors.password || errors.firstName || errors.lastName) {
                    this.setState({currentStep: SIGNUP_STEP, validation: errors, complete: true});
                } else if (errors.intention_id) {
                    this.setState({currentStep: INTENTION_STEP, validation: errors, complete: true});
                } else if (errors.words) {
                    this.setState({currentStep: WORD_GOAL_STEP, validation: errors, complete: true});
                }
            });
    }

    render() {
        let currentStep;

        switch (this.state.currentStep) {
            default:
                currentStep = (
                    <Signup
                        user={this.state.user}
                        validation={this.state.validation}
                        handleChange={this.handleChange}
                        complete={this.complete}
                    />
                );
                break;
            case INTENTION_STEP:
                currentStep = (
                    <Intention
                        user={this.state.user}
                        handleChange={this.handleChange}
                    />
                );
                break;
            case WORD_GOAL_STEP:
                currentStep = (
                    <WordGoal
                        user={this.state.user}
                        handleChange={this.handleChange}
                    />
                );
                break;
            case START_STEP:
                currentStep = <Start user={this.state.user} complete={this.complete}/>;
                break;
        }

        let previousStepBtn;
        if (this.state.currentStep >= INTENTION_STEP) {
            previousStepBtn = <button id="previous-step" onClick={this.previousStep}/>;
        }

        return (
            <div className="row align-items-center" id="sign-page">
                <div id="stepper">
                    <ul>
                        <li className={`${!this.state.currentStep || this.state.currentStep === SIGNUP_STEP ? 'active' : ''} ${this.state.currentStep > SIGNUP_STEP ? 'passed' : ''}`}>
                            <span>{SIGNUP_STEP}</span>
                            {' '}
                            Signup
                        </li>
                        <li className={`${this.state.currentStep === INTENTION_STEP ? 'active' : ''} ${this.state.currentStep > INTENTION_STEP ? 'passed' : ''}`}>
                            <span>{INTENTION_STEP}</span>
                            Intention
                        </li>
                        <li className={`${this.state.currentStep === WORD_GOAL_STEP ? 'active' : ''} ${this.state.currentStep > WORD_GOAL_STEP ? 'passed' : ''}`}>
                            <span>{WORD_GOAL_STEP}</span>
                            Word Goal
                        </li>
                        <li className={`last ${this.state.currentStep === START_STEP ? 'active' : ''}`}>
                            <span>{START_STEP}</span>
                            Start
                        </li>
                    </ul>
                </div>
                <div className="card sign-up-card animate__animated animate__fadeIn">
                    {previousStepBtn}
                    {currentStep}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
    authorize: (user) => dispatch(actions.authorize(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
