import * as actionTypes from './actionTypes';
import axios from 'axios';
import {formatTime} from "../utility";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user
    };
};

export const setProfile = (user) => {
    return {
        type: actionTypes.SET_PROFILE,
        user: user
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const authLogout = () => {
    localStorage.removeItem('user');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const redirectToWriting = () => {
    if (window.location.pathname === '/writing') {
        return false;
    }
    setTimeout(() => {
        window.location.replace('/writing');
    }, 100);
}

export const updateLocalStorage = (user) => {
    if (localStorage.getItem('user')) {
        user = Object.assign(JSON.parse(localStorage.getItem('user')), user)
    }

    localStorage.setItem('user', JSON.stringify(user));

    return user;
}

export const updateProfile = (user) => {
    return dispatch => {
        user = updateLocalStorage(user);
        dispatch(setProfile(user));
    };
}

export const authorize = (user) => {
    return async (dispatch) => {
        await updateLocalStorage(user);
        await window.location.replace('/writing');
        await dispatch(authSuccess(user));
    };
}

export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            password: password
        };
        axios.post('/sign-in', authData)
            .then(async (response) => {
                const data = response.data;

                if (data.reminder) {
                    data.reminder = formatTime(data.reminder);
                }

                const user = updateLocalStorage(data);
                dispatch(authSuccess(user));
            })
            .finally(() => {
                redirectToWriting();
            })
            .catch(err => {
                dispatch(authFail(err));
            })
    };
};

export const logout = () => {
    return dispatch => {
        dispatch(authLogout());
    };
};
