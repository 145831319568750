import React, {Component} from 'react';
import { Link } from "react-router-dom";
import {getDate} from "../../../store/utility";

class Day extends Component {
    isCurrentDay() {
        return getDate(new Date(this.props.year, this.props.month, this.props.day.number)).date === getDate().date;
    }
    isCurrentMonth() {
        return getDate(new Date(this.props.year, this.props.month, this.props.day.number)).date === getDate().date;
    }

    getDateUrl = (year, month, day) => {
        return '/past/' + getDate(new Date(year, month, day), true).date;
    }

    render() {
        let day = null;
        if (this.props.day) {
            if (this.props.day.number) {
                if (this.isCurrentDay()) {
                    day = (
                        <Link to={this.getDateUrl(this.props.year, this.props.month, this.props.day.number)}
                              className='calendar-btn draft'>
                            {this.props.day.number}
                        </Link>
                    )
                } else if (this.props.day.emote) {
                    day = (
                        <Link to={this.getDateUrl(this.props.year, this.props.month, this.props.day.number)}
                              className='calendar-btn success'>
                            {this.props.day.number}
                        </Link>
                    );
                } else {
                    day = (
                        <span className={'calendar-btn'}>
                            {this.props.day.number}
                        </span>
                    )
                }
            } else {
                day = (
                    <span className={'calendar-btn'}>
                            {this.props.day.number}
                        </span>
                )
            }
        }

        return (
            <li className="col text-center">
                {day}
            </li>
        )

    }
}
export default Day;
