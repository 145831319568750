const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

export const prepareEmotes = (emotes) => {
    const result = [];
    emotes.forEach((emote) => {
        emote.created_at = new Date(emote.created_at);
        const date = getDate(emote.created_at, true).date;
        if (!result[date]) {
            result[date] = emote;
        }
    });
    let streak = 0;
    let date = getDate(null, true).date;
    let today = result[date] ? result[date] : null;
    while(result[date]) {
        streak++;
        date = getDate(new Date(Date.now() - streak * 24 * 3600 * 1000), true).date;
    }
    return {
        emotes: result,
        streak: streak,
        today: today
    };
}

export const getMonthName = () => {
    let i = localStorage.getItem('month');
    return monthNames[parseInt(i) - 1];
};

export const isToday = (date) => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
}

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const formatTime = (t) => {
    const time = t ? t.split(':') : null;

    if (!time) {
        return false;
    }

    let result;
    if (time[0] > 12) {
        result = String(time[0] - 12).padStart(2, '0') + ':' + time[1] + ' pm';
    } else {
        result = String(time[0]).padStart(2, '0') + ':' + time[1] + ' am';
    }
    return result;
};

export const unformatTime = (time) => {
    const match = time.match(/^(\d{1,2}):(\d{1,2})\s(am|pm)$/);

    if (!match) {
        return time;
    }

    match.shift();

    const [hours, minutes, type] = match;

    return (type === 'pm' ? parseInt(hours) + 12 : hours).toString().padStart(2, '0') + ':' + minutes;
};

export const getDate = (date = '', toString = false, m = null, toUTC = false) => {
    let d;
    if (date instanceof Date) {
        d = date;
    } else {
       d = date ? new Date(date) : new Date();
    }
    const year = toString ? String(toUTC ? d.getUTCFullYear() : d.getFullYear()) :
        toUTC ? d.getUTCFullYear() : d.getFullYear();
    const month = toString ? String((toUTC ? d.getUTCMonth() : d.getMonth()) + 1).padStart(2, '0') :
        (toUTC ? d.getUTCMonth() : d.getMonth()) + 1;
    const day = toString ? String(toUTC ? d.getUTCDate() : d.getDate()).padStart(2, '0') :
        toUTC ? d.getUTCDate() : d.getDate();

    return {
        year: year,
        month: month,
        monthName: monthNames[toUTC ? d.getUTCMonth() : d.getMonth()],
        day: day,
        time: formatTime((toUTC ? d.getUTCHours() : d.getHours()) + ':' +
            (toUTC ? d.getUTCMinutes() : d.getMinutes()) + ':' +
            (toUTC ? d.getUTCSeconds() : d.getSeconds())),
        days: m ? new Date(year, parseInt(m) + 1, 0).getDate() :
            new Date(year, d.getMonth() + 1, 0).getDate(),
        date: year + '-' + month + '-' + day,
        raw: d
    }
};

export const timeDiffCalc = (dateFuture) => {
    const dateNow = new Date();
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    let difference = '';
    if (days > 0) {
        difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    }
    difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
    difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;
    return difference;
};
