import React, {Component} from 'react';
import axios from 'axios';
import Loader from '../../components/UI/Loader/Loader';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

class NewPassword extends Component {
    state = {
        password: '',
        email: '',
        code: '',
        hidden: true,
        loading: false,
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const updatedState = {...this.state};
        for (const p of params) {
            updatedState[p[0]] = p[1];
        }
        this.setState(updatedState);
        this.inputElement.focus();
    }

    validateField = (value) => {
        let validation = this.state.validation;

        if (!value) {
            validation = 'Password cannot be empty';
        } else if (!value.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+~])[A-Za-z\d!@#$%^&*()_+~]{8,}$/)) {
            validation = 'Password should contain at least 8 characters, 1 special symbol, 1 number and one uppercase';
        } else if (validation) {
            validation = null;
        }

        this.setState({validation});
    }

    passwordChangedHandler = (value) => {
        this.setState({password: value}, () => {
            this.validateField(value)
        })
    };

    send = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            loading: true,
        });
        const data = {
            email: this.state.email,
            password: this.state.password,
            code: this.state.code,
        };
        axios.post('/new-password', data)
            .then((response) => {
                this.props.history.push('/login');
            })
            .catch((err) => {
                this.setState({
                    ...this.state,
                    loading: false,
                });
            });
    };

    toggleShow = () => {
        this.setState({
            ...this.state,
            hidden: !this.state.hidden,
        });
    };

    render() {
        return (
            <div className="row justify-content-center align-items-center" id="sign-page">
                <div className="card sign-in-card">
                    <h1 className="text-center">New Password</h1>
                    <form onSubmit={this.send}>
                        <div className="form-group">
                            <div className="input-group">
                                <input
                                    type={this.state.hidden ? 'password' : 'text'}
                                    name="password"
                                    className={"form-control " + (this.state.validation ? 'error' : '')}
                                    placeholder="Password"
                                    ref={(inputEl) => {
                                        this.inputElement = inputEl;
                                    }}
                                    onChange={(event) => this.passwordChangedHandler(event.target.value)}
                                    value={this.state.password}
                                />
                                <div className="input-group-append">
                                    <i className="icon icon-show-password" onClick={this.toggleShow}/>
                                </div>
                            </div>
                            {this.state.validation ?
                                <span className="form-error">{this.state.validation}</span> : ''}
                        </div>
                        <div className="form-group"
                             style={{marginTop: '40px'}}>
                            <button
                                type="submit"
                                className="btn btn-danger text-uppercase d-block login-button"
                                disabled={!this.state.password || this.state.validation}
                            >
                                Send
                            </button>
                        </div>
                    </form>
                </div>
                {this.state.loading ? <Loader/> : null}
            </div>
        );
    }
}

export default withErrorHandler(NewPassword, axios);
