import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import axios from 'axios';
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import * as actions from "../../store/actions";
import Quote from "../../components/Content/Quote";
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import Streak from "../../components/Content/Goals/Streak";
import {getDate} from "../../store/utility";
import {EXCLUDED_WORDS} from "./excluded-words.const";

class Home extends Component {
    constructor(props) {
        super(props)
        this.cloud = React.createRef()
    }

    state = {
        cloudText: null,
        today: getDate(null, true).date,
        date: null,
        emote: null
    };

    async componentDidMount() {
        await this.props.getUserEmotes();
        await this.prepareData();

        this.source = axios.CancelToken.source();

        const {data: total} = await axios.get('/emotes/total', {
            cancelToken: this.source.token
        });

        this.setState({...total});
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            await this.prepareData();
        }
        if (this.props.location.pathname !== prevProps.location.pathname) {
            await this.prepareData();
        }
    }

    prepareCloudText = (text) => {
        let specialChars = "!@#$^&%*()+=-[]/{}|:;<>?,.'";
        let wordsMap = [];
        for (let i = 0; i < specialChars.length; i++) {
            text = text.replace(new RegExp("\\" + specialChars[i], "gi"), "");
        }
        text.split(/\s+/).forEach((key) => {
            if (!EXCLUDED_WORDS.includes(key.toLowerCase())) {
                let index = wordsMap.map(e => e.text).indexOf(key.toLowerCase());
                if (index !== -1) {
                    wordsMap[index]['value']++;
                } else {
                    wordsMap.push({
                        'text': key.toLowerCase(),
                        'value': 1
                    });
                }
            }
        });
        return wordsMap.sort((a, b) => {
            return b.value - a.value
        }).slice(0, 25);
    }

    async prepareData() {
        let date = this.props.location.pathname.replace('/', '');
        if (!date) {
            date = this.state.today
        }
        let emote = this.props.emotes && this.props.emotes.emotes && this.props.emotes.emotes[date] ?
            this.props.emotes.emotes[date] : null;

        await this.setState({
            date: date,
            emote: emote,
            cloudText: emote ? this.prepareCloudText(emote.text) : ''
        });
    }

    getDateString(date) {
        const d = getDate(date, false, null, true);
        return d.monthName + ' ' + d.day + ', ' + d.year;
    }

    getPrevUrl = () => {
        if (this.props.emotes && this.props.emotes.emotes) {
            const dates = Object.keys(this.props.emotes.emotes);
            const index = dates.indexOf(this.state.date);
            const emote = dates[index + 1];
            if (emote) {
                return `/${emote}`;
            }
        }
        return false;
    }

    getNextUrl = () => {
        if (this.props.emotes && this.props.emotes.emotes) {
            const dates = Object.keys(this.props.emotes.emotes);
            const index = dates.indexOf(this.state.date);
            const emote = dates[index - 1];
            if (emote) {
                return `/${emote}`;
            }
        }
        return false;
    }

    render() {
        const wordsCloud = this.state.cloudText ? (
            <ReactWordcloud words={this.state.cloudText}
                            options={{fontFamily: 'Pacifico', fontWeight: 300, fontSizes: [18, 40]}}/>
        ) : null;

        const isToday = this.state.date === this.state.today;
        const intention = (this.state.emote && this.state.emote.prompt && this.state.emote.prompt.intention &&
            this.state.emote.prompt.intention.name) ? this.state.emote.prompt.intention.name : (
            this.props.user && this.props.user.intention && this.props.user.intention.name ?
                this.props.user.intention.name : ''
        );

        const minutes = this.state.emote && this.state.emote.timespent ? Math.floor(this.state.emote.timespent / 60) : 0;
        const seconds = this.state.emote && this.state.emote.timespent ? this.state.emote.timespent % 60 : 0

        return (
            <div id={"home"}>
                <Streak/>
                <div id={"controls"}>
                    {this.getPrevUrl() ?
                        <Link to={this.getPrevUrl()}>
                            <span className="icon icon-prev-day"/>
                        </Link> : null}
                    {isToday ? "Today's Emote" : this.getDateString(this.state.date)}
                    {this.getNextUrl() ?
                        <Link to={this.getNextUrl()}>
                            <span className="icon icon-next-day"/>
                        </Link> : null}

                </div>
                {isToday && (!this.state.emote || !this.state.emote.text || !this.state.emote.done) ? (
                    <div className="row">
                        <div className="col-12 col-xs-12 text-center" id="start-now">
                            <h3>If not now, when?</h3>
                            <Link to="/writing"
                                  className="btn btn-success text-uppercase start-btn">Write now!</Link>
                        </div>
                    </div>
                ) : (
                    <div id={"day-statistics"} className={"plate"}>
                        <div className={"col-12 col-md-5"}>
                            <div className="box">
                                <h4>{this.state.emote && this.state.emote.words ? this.state.emote.words.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} Words</h4>
                            </div>
                            <div className="box">
                                <h4>{minutes} Min&nbsp;:&nbsp;{seconds} Sec</h4>
                            </div>
                        </div>
                        <div className={"col-12 col-md-7"}>
                            <div className="box">
                                <div className={"text-center"}>
                                    Intention: <span className={'intention-title'}>{intention}</span>
                                </div>
                                <div id={"word-cloud"} ref={this.cloud}>
                                    {wordsCloud}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div id="user-statistics">
                    <h3>Emote Community</h3>
                    <div className={"plate"}>
                        <div className={"col-12 col-md-6"}>
                            <div className={"box"}>
                                <h4>{this.state.count ? this.state.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h4>
                                <p>Total Emote Submissions Today</p>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <div className={"box"}>
                                <h4>{this.state.count ? (264 * this.state.count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</h4>
                                <p><b>Words written TODAY</b><br/>by Emote members</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Quote/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        emotes: state.user.emotes
    }
};

const mapDispatchToProps = (dispatch) => ({
    getUserEmotes: () => dispatch(actions.getUserEmotes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Home, axios));
